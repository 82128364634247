<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="3">
              <date-picker
                v-model="searchData.date_range"
                :shortcuts="false"
                :editable="false"
                :clearable="false"
                range-separator="~"
                range
                width="100%"
                value-type="date"
                format="YYYY-MM-DD"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CSelect
                :placeholder="$t('search_a') + $t('status')"
                :options="statusOptions"
                :value.sync="searchData.status"
              />
            </CCol>
            <CCol col="12" sm="4">
              <CInput
                :placeholder="$t('search_a') + $t('order_no') + $t('or') + $t('booking_name') + $t('or') + $t('booking_phone')"
                v-model="searchData.search_str"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getOrderRooms()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <vue-excel-xlsx
                class="btn btn-success btn-block"
                :disabled="data.length === 0"
                :data="excelData()"
                :columns="excelColumns"
                :fileName="$route.name"
                :sheetName="searchData.date_range.map(v => $moment(v).format('YYYY-MM-DD')).join('-')"
                >
                {{ $t('export') }}
              </vue-excel-xlsx>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="order.order_info.booking_note" slot-scope="props" v-html="props.row.order.order_info.booking_note?.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>')">
            </font>
            <font slot="order.order_info.agent_note" slot-scope="props" v-html="props.row.order.order_info.agent_note?.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>')">
            </font>
            <font slot="stocks" slot-scope="props" v-html="props.row.order_room_stocks.map(stock => `${stock.room_quantity_unit_name}： ${stock.quantity} ${stock.room_quantity_unit_measure}`).join('<br>')">
            </font>
            <font slot="options" slot-scope="props" v-html="props.row.order_room_options.map(option => option.room_option_name).join('<br>')">
            </font>
            <font slot="order.status" slot-scope="props">
              {{ $_.find(statusOptions, option => { return option.value === props.row.order.status }).label }}
            </font>
            <font slot="start_date" slot-scope="props">
              {{ $moment(props.row.start_date).format('YYYY-MM-DD') }}
            </font>
            <font slot="end_date" slot-scope="props">
              {{ $moment(props.row.end_date).format('YYYY-MM-DD') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editOrderAgentNoteModal = { data: { id: props.row.order_id }, modal: true }">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <EditOrderAgentNoteModal :data="editOrderAgentNoteModal.data" :show="editOrderAgentNoteModal.modal" @showUpdate="show => editOrderAgentNoteModal.modal = show" @formSubmit="getOrderRooms()" />

    </CCol>
  </CRow>
</template>

<script>
import EditOrderAgentNoteModal from './modal/EditOrderAgentNoteModal'

export default {
  name: 'orderRooms',
  components: {
    EditOrderAgentNoteModal,
  },
  data () {
    return {
      columns: [ 'order.sn', 'order.status', 'order.order_info.booking_name', 'order.order_info.booking_phone', 'order.order_info.booking_note', 'order.order_info.agent_note', 'room_name', 'stocks', 'options', 'start_date', 'end_date', 'action' ],
      data: [],
      options: {
        headings: {
          'order.sn': this.$t('order_no'),
          'order.status': this.$t('status'),
          'order.order_info.booking_name': this.$t('booking_name'),
          'order.order_info.booking_phone': this.$t('booking_phone'),
          'order.order_info.booking_note': this.$t('booking_note'),
          'order.order_info.agent_note': this.$t('agent_note'),
          room_name: this.$t('room'),
          stocks: this.$t('quantity'),
          options: this.$t('options'),
          start_date: this.$t('check_in'),
          end_date: this.$t('check_out'),
          action: this.$t('action')
        },
        sortable: [ 'order.sn', 'order.status', 'order.order_info.booking_name', 'order.order_info.booking_phone', 'order.order_info.booking_note', 'order.order_info.agent_note', 'room_name', 'stocks', 'options', 'start_date', 'end_date' ],
        filterable: [ 'order.sn', 'order.status', 'order.order_info.booking_name', 'order.order_info.booking_phone', 'order.order_info.booking_note', 'order.order_info.agent_note', 'room_name', 'stocks', 'options', 'start_date', 'end_date' ]
      },
      statusOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('status') + '...' },
        { value: 'pending', label: this.$t('pending') },
        { value: 'completed', label: this.$t('completed') },
        { value: 'confirming', label: this.$t('confirming') },
        { value: 'refunding', label: this.$t('refunding') },
        { value: 'refunded', label: this.$t('refunded') },
        { value: 'cancelled', label: this.$t('cancelled') },
        { value: 'expired', label: this.$t('expired') },
        { value: 'closed', label: this.$t('closed') },
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { date_range: [this.$moment({hour: 0}).subtract(30, 'd'), this.$moment({hour: 0}).add(30, 'd')], status: null, search_str: '' },
      excelColumns: [
        { label: this.$t('order_no'), field: 'order.sn' },
        { label: this.$t('status'), field: 'order.status', dataFormat: v => this.$_.find(this.statusOptions, option => { return option.value === v }).label },
        { label: this.$t('booking_name'), field: 'order.order_info.booking_name' },
        { label: this.$t('booking_phone'), field: 'order.order_info.booking_phone' },
        { label: this.$t('booking_note'), field: 'order.order_info.booking_note' },
        { label: this.$t('room'), field: 'room_name' },
        { label: this.$t('quantity'), field: 'order_room_stocks', dataFormat: v => v.map(stock => `${stock.room_quantity_unit_name}： ${stock.quantity} ${stock.room_quantity_unit_measure}`).join("\n") },
        { label: this.$t('options'), field: 'order_room_options', dataFormat: v => v.map(option => option.room_option_name).join("\n") },
        { label: this.$t('check_in'), field: 'start_date', dataFormat: v => this.$moment(v).format('YYYY-MM-DD')},
        { label: this.$t('check_out'), field: 'end_date', dataFormat: v => this.$moment(v).format('YYYY-MM-DD') },
      ],
      editOrderAgentNoteModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getOrderRooms()
  },
  methods: {
    getOrderRooms () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getOrderRooms', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    excelData () {
      return this.data
    },
  }
}
</script>
